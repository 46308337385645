<template>
  <div>
    <div v-show="supplierDiv">
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition">
            <el-form ref="form" :model="customerForm">
              <!-- <el-form-item label="企业名称:">
                            <el-input v-model="customerForm.cnCompany"/>
                        </el-form-item>
                        <el-form-item label="企业类型:" prop="enterpriseType">
                            <el-select v-model="customerForm.enterpriseType" class="select_class">
                                <el-option v-for="item in cnTypeOptions" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="企业评级:">
                            <el-select v-model="customerForm.aaaRating" class="select_class">
                                <el-option v-for="item in rateOptions" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
                            </el-select>
                        </el-form-item> -->
              <el-form-item label="选择偏好:">
                <el-select v-model="customerForm.preference" class="select_class">
                  <el-option v-for="item in preOptions" :key="item.name" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="成立时间:">
                <el-select v-model="customerForm.establishmentTime" class="select_class">
                  <el-option v-for="item in DateOptions" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>

            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="cleargetDate()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="collBox1">
            <label class="collLab">选择公司数量（家）</label>
            <el-select v-model="customerForm.companyNum" class="select_class" @change="SelectCompanyNum">
              <el-option v-for="item in CompanyNum" :key="item.codeState" :label="item.value" :value="item.codeState"></el-option>
            </el-select>
          </div>
          <div class="collBox">
            <label class="collLab">查看已收藏企业</label>
            <el-switch v-model="checkCollect" active-color="#339C9B" inactive-color="#7C969B" @change='checkColl()'></el-switch>
          </div>
          <div class="table_box_bottom table_tab_div">
            <el-table :data="tableData" stripe border style="width: 100%">
              <template slot="empty">
                <div class="no_data">
                  <img src="@/assets/img/nodata3.png" />
                  <div class="bigTitle">暂无数据，请在“<a class="title" href="#" @click="toFunctionDefinition(0)">功能定义</a>”画面中设置相关的偏好后便可获取到您想要的数据</div>
                </div>
              </template>
              <el-table-column label="企业名称" prop="cnCompany" align="left" header-align="center" class-name="financing_cursor" show-overflow-tooltip :resizable="false" :width="width180">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.cnCompany}}</div>
                </template>
              </el-table-column>
              <el-table-column label="企业类型" prop="enterpriseTypeName" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="所属行业" prop="industryName" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="主要产品" prop="mainProducts" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="企业评级" prop="aaaRating" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="地 址" prop="address" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="电 话" prop="tel" align="center" show-overflow-tooltip :resizable="false" :width="width138" />
              <el-table-column label="邮 箱" prop="email" align="center" show-overflow-tooltip :resizable="false" />
              <el-table-column label="成立时间" prop="establishmentTime" align="center" show-overflow-tooltip :resizable="false" :width="width138" />
              <el-table-column label="操 作" prop="operation" fixed="right" align="center" :resizable="false">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip effect="light" :content="scope.row.isCollection=='0'?'未收藏':'已收藏'" placement="top">
                      <i class="iconfont icon-xin2" :class="scope.row.isCollection=='0'?'icon-coll':'icon-coll-on'" @click="collectCompany(scope.row)"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <span class="my_total_class">搜索到{{page.total}}条数据</span>
            <Page :totalFont="true" :total="page.total" :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>

          </div>
        </div>
      </div>
    </div>
    <div v-show="detailDisplay">
      <!-- <div class="second_return_icon" @click="returnButton()">
                <i class="iconfont icon-fanhui" ></i>
                获客详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
  </div>
</template>
<script>
import { findCodeInfoByKey } from "@/api/api.js";
import { getRecommendList, setCollection, getPreList } from "@/api/coreEnterprise.js";
import Page from "@/components/page"
import Company from '../../supplier/components/companyDetail.vue'
import { mixins1 } from "@/mixins/index";
export default {
  mixins: [mixins1],
  components: { Page, Company },
  data() {
    return {
      menuList: [
        {
          name: '功能定义',
          path: '/system/supplier/functionDefinition'
        },
      ],
      detailDisplay: false,
      supplierDiv: true,
      tableData: [
        // {cnCompany:'',enterpriseType:'',industry:'',mainProducts:'',aaaRating:'',address:'',tel:'',email:'',createDate:'',isCollection:0}
      ],
      customerForm: { cnCompany: '', enterpriseType: '', establishmentTime: '', aaaRating: '', preference: '', companyNum: '' },
      // rateOptions:[],
      preOptions: [],
      checkCollect: false,
      pageSize: 8,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      type: '0',
      isCollection: '0',
      // cnTypeOptions:[],
      DateOptions: [],
      CompanyNum: [],//公司数量
      haveNum: false,
      myTotal: 0,
      sizeS: '',
      clearSize: ''
    }
  },
  created() {
    let that = this
    that.centerOptionsList().then(function (res) {
      if (that.customerForm.preference) {
        if (that.preOptions.length > 0) {
          let size = ''
          that.getCustomerList(size)
        }
      }
    })
  },
  methods: {
    async SelectCompanyNum(val) {//选择公司数量
      if (val == 1) {
        if (this.customerForm.preference != null || this.customerForm.preference != "") {//选择偏好不为空的时候才调用接口
          console.log('ok')
          this.myTotal = 10
          let establishmentTime = this.customerForm.establishmentTime
          let preference = this.customerForm.preference
          let isCollection = this.isCollection + ''
          let pageNo = this.page.pageNo
          let pageSize = this.page.pageSize
          let type = this.type
          let res = await getRecommendList('size=' + 10 + '&establishmentTime=' + establishmentTime + '&preference=' + preference + '&isCollection=' + isCollection + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&type=' + type)
          if (res && res.code == 200) {
            let reaList = res.data.records
            res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
            res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
          } else {
            this.$message.error(res.msg)
          }
        }
      } else if (val == 2) {
        if (this.customerForm.preference != null && this.customerForm.preference != "") {
          this.myTotal = 50
          let establishmentTime = this.customerForm.establishmentTime
          let preference = this.customerForm.preference
          let isCollection = this.isCollection + ''
          let pageNo = this.page.pageNo
          let pageSize = this.page.pageSize
          let type = this.type
          let res = await getRecommendList('size=' + 50 + '&establishmentTime=' + establishmentTime + '&preference=' + preference + '&isCollection=' + isCollection + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&type=' + type)
          if (res && res.code == 200) {
            let reaList = res.data.records
            res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
            res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
          } else {
            this.$message.error(res.msg)
          }
        }
      } else if (val == 3) {
        if (this.customerForm.preference != null && this.customerForm.preference != "") {
          this.myTotal = 100
          let establishmentTime = this.customerForm.establishmentTime
          let preference = this.customerForm.preference
          let isCollection = this.isCollection + ''
          let pageNo = this.page.pageNo
          let pageSize = this.page.pageSize
          let type = this.type
          let res = await getRecommendList('size=' + 100 + '&establishmentTime=' + establishmentTime + '&preference=' + preference + '&isCollection=' + isCollection + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&type=' + type)
          if (res && res.code == 200) {
            let reaList = res.data.records
            res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
            res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
          } else {
            this.$message.error(res.msg)
          }
        }
      } else {
        if (this.customerForm.preference != null && this.customerForm.preference != "") {
          this.sizeS = ''
          this.getCustomerList(this.sizeS)
        }
      }
    },
    //当无数据时，点击图片跳转至功能定义
    toFunctionDefinition(index) {
      this.$router.push(this.menuList[index].path)
    },
    // 返回上一页
    returnButton() {
      this.detailDisplay = false
      this.supplierDiv = true
      window.scrollTo(0, 0)
    },
    tableChange(index) {
      this.tableActiveIndex = index
      this.type = index + ''
      this.getCustomerList()
    },
    //字段合作年限
    async centerOptionsList() {
      // let res = await findCodeInfoByKey('ms=8')
      // this.cnTypeOptions = res.data
      // let res1 = await findCodeInfoByKey('ms=9')
      // this.rateOptions = res1.data
      let res2 = await getPreList('type=1')
      this.preOptions = res2.data
      this.preOptions.forEach((item) => {
        if (item.flag == '1') {
          this.customerForm.preference = item.name
          return false
        }
      })
      let res3 = await findCodeInfoByKey('ms=22')
      let res4 = await findCodeInfoByKey('ms=30')
      console.log(res4)
      this.DateOptions = res3.data
      this.CompanyNum = res4.data
    },
    //获客列表
    async getCustomerList(sizeS) {
      let establishmentTime = this.customerForm.establishmentTime
      let preference = this.customerForm.preference
      let isCollection = this.isCollection + ''
      let pageNo = this.page.pageNo
      let pageSize = this.page.pageSize
      let type = this.type
      let res = await getRecommendList('size=' + sizeS + '&establishmentTime=' + establishmentTime + '&preference=' + preference + '&isCollection=' + isCollection + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&type=' + type)
      if (res && res.code == 200) {
        res.data && res.data.records != null ? this.tableData = res.data.records : this.tableData = []
        res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
        this.myTotal = res.data.total

      } else {
        this.$message.error(res.msg)
      }

    },
    //分页检索
    async getCustomer(pageNo, pageSize, sizeS) {
      let establishmentTime = this.customerForm.establishmentTime
      let preference = this.customerForm.preference
      let isCollection = this.isCollection + ''
      let type = this.type
      let res = await getRecommendList('size=' + sizeS + '&establishmentTime=' + establishmentTime + '&preference=' + preference + '&isCollection=' + isCollection + '&pageNo=' + pageNo + '&pageSize=' + pageSize + '&type=' + type)
      if (res && res.code == 200) {
        if (this.customerForm.companyNum == 1) {
          let reaList = res.data.records
          res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
          res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
        }
        else if (this.customerForm.companyNum == 2) {
          console.log(this.customerForm.companyNum)
          let reaList = res.data.records
          res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
          res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
        }
        else if (this.customerForm.companyNum == 3) {
          let reaList = res.data.records
          res.data && res.data.records != null ? this.tableData = reaList : this.tableData = []
          res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
        }
        else if (!this.customerForm.companyNum) {
          res.data && res.data.records != null ? this.tableData = res.data.records : this.tableData = []
          res.data && res.data.records != null ? this.page.total = res.data.total : this.page.total = 0
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    //重置
    searchForm() {
      this.page.pageNo = 1
      if (this.customerForm.companyNum == 1) {
        this.sizeS = 10
      } else if (this.customerForm.companyNum == 2) {
        this.sizeS = 50
      } else if (this.customerForm.companyNum == 3) {
        this.sizeS = 100
      }
      this.getCustomerList(this.sizeS)
    },
    cleargetDate() {
      this.customerForm.companyNum = ''
      this.customerForm.establishmentTime = ''
      this.customerForm.preference = ''
      this.isCollection = '0'
      this.pageNo = 1
      this.pageSize = 10
      this.type = '0'
      this.page.pageNo = 1
      this.clearSize = ''
      this.getCustomerList(this.clearSize)
    },
    //获客详情页
    detailIndex(row) {
      console.log(row)
      this.$refs.Company.getCompany(row.cnCompany)
      this.supplierDiv = false
      this.detailDisplay = true
    },
    //收藏企业
    async collectCompany(row) {
      //String collection, 0取消收藏，1收藏
      //String collectionId,被收藏人ID
      //String cnCompany ，收藏人的公司名称
      let cnCompany = row.cnCompany
      let enterpriseType = row.enterpriseType
      let type = this.type
      let preference = this.customerForm.preference
      let establishTime = this.customerForm.establishmentTime


      let collection = ''
      row.isCollection == '0' ? collection = '1' : collection = '0'

      let collectionId = ''
      // type=='0'?collectionId=row.supplyRoleId:collectionId=row.enterRoleId

      let res = await setCollection('collection=' + collection + '&collectionId=' + collectionId + '&cnCompany=' + cnCompany + '&type=' + type
        + '&preference=' + preference + '&establishTime=' + establishTime + '&enterpriseType=' + enterpriseType)
      if (res && res.code == 200) {
        if (this.customerForm.companyNum == 1) {
          this.sizeS = 10
        } else if (this.customerForm.companyNum == 2) {
          this.sizeS = 50
        } else if (this.customerForm.companyNum == 3) {
          this.sizeS = 100
        }
        this.getCustomerList(this.sizeS)
      } else {
        this.$message.error(res.msg)
      }
    },
    //列表是否收藏
    checkColl() {
      if (this.checkCollect) {
        this.isCollection = '1'
        this.clearSize = ''
        this.getCustomerList(this.clearSize)
      } else {
        this.isCollection = '0'
        if (this.customerForm.companyNum == 1) {
          this.sizeS = 10
        } else if (this.customerForm.companyNum == 2) {
          this.sizeS = 50
        } else if (this.customerForm.companyNum == 3) {
          this.sizeS = 100
        }
        this.getCustomerList(this.sizeS)
      }
    },
    async onPageChange(val) {
      this.page.pageNo = val
      this.page.pageSize = this.pageSize
      if (this.customerForm.companyNum == 1) {
        this.sizeS = 10
      } else if (this.customerForm.companyNum == 2) {
        this.sizeS = 50
      } else if (this.customerForm.companyNum == 3) {
        this.sizeS = 100
      }
      this.getCustomer(this.page.pageNo, this.page.pageSize, this.sizeS)
    },
  }
}
</script>
<style scoped>
  ::v-deep .totalFont {
    display: none !important;
  }
  .my_total_class {
    position: relative;
    top: 14px;
    font-size: 2px;
    color: #92a2bc;
  }
  ::v-deep .pageBox {
    margin-top: -14px;
  }
  .bigTitle {
    color: #7c969bff;
    margin-left: 25%;
    text-align: center;
    font-family: MicrosoftYaHei;
    width: 348px;
    margin-top: -2%;
    line-height: 20px;
  }
  .title {
    cursor: pointer;
    color: #659dfcff;
    text-decoration: underline;
  }
  .collBox {
    text-align: right;
    margin-bottom: 20px;
  }
  .collBox1 {
    float: left;
    text-align: left;
    margin-bottom: 20px;
  }
  .collLab {
    color: #162747;
    font-size: 14px;
    line-height: 40px;
    margin-right: 10px;
  }
  .search_condition {
    flex: 1;
  }
  ::v-deep .el-switch__core {
    width: 60px !important;
    height: 30px;
    border-radius: 25px;
  }
  ::v-deep .el-switch__core:after {
    width: 28px;
    height: 28px;
    background: #edf2f5;
    top: 0;
    left: 0;
  }
  ::v-deep .el-switch.is-checked .el-switch__core::after {
    margin-left: -27px;
  }
  .icon-xin2 {
    font-size: 20px;
  }
  .icon-coll {
    color: #e1e7ea;
  }
  .icon-coll-on {
    color: #fb6476;
  }
  ::v-deep .el-input__prefix {
    left: 91%;
    top: -4px;
  }
  .no_data {
    width: 100%;
    height: 400px;
    text-align: center;
  }
  .no_data img {
    width: 293px;
    height: 130px;
    margin-top: 88px;
  }
  @media screen and (max-width: 1664px) {
    .my_total_class {
      position: relative;
      top: 9px;
      font-size: 12px;
      color: #92a2bc;
    }
    ::v-deep .pageBox {
      margin-top: -23px;
    }
    .bigTitle {
      color: #7c969bff;
      margin-left: 25%;
      text-align: center;
      font-family: MicrosoftYaHei;
      width: 290px;
      margin-top: -2%;
      line-height: 20px;
    }
    .title {
      color: #659dfcff;
      text-decoration: underline;
    }
    .collBox {
      margin-bottom: 13px;
    }
    .collLab {
      font-size: 12px;
      line-height: 30px;
      margin-right: 8px;
    }
    ::v-deep .el-switch__core {
      width: 40px !important;
      height: 20px;
      border-radius: 17px;
    }
    ::v-deep .el-switch__core:after {
      width: 18px;
      height: 18px;
      top: 0;
    }
    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -17px;
    }
    .icon-xin2 {
      font-size: 14px;
    }
    ::v-deep .el-input__prefix {
      left: 90%;
      top: -2px;
    }
    .no_data {
      height: 264px;
    }
    .no_data img {
      width: 193px;
      height: auto;
      margin-top: 58px;
      right: 41.5%;
    }
  }
</style>